import React, { FC, useEffect, useState } from 'react'
import en from 'src/constants/en'
import { FilterModalContentWrapper } from 'src/containers/FilterModalContentWrapper'
import { FilterSearchDropdownSection } from 'src/containers/FilterSearchDropdownSection'
import {
  AnalyticsFetchSentimentAnalysisResponseDataKeywordItem,
  AnalyticsFetchSentimentAnalysisResponseDataSourceItem,
  AnalyticsFetchThreadsIsLostType,
  LanguageItem,
  MentionFormatItem,
} from 'src/services/__generated__/api'
import { MentionItem } from 'src/components/MentionItem'
import { FilterDropdownSelectOption } from 'src/containers/FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'
import { CheckBox } from 'src/components/CheckBox'
import { ReactComponent as LinkSvg } from 'src/assets/svg/link.svg'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { capitalizeWords } from 'src/utils/capitalizeWords'
import styles from './filterModalContent.module.scss'

export interface FilterModalContentProps {
  allKeywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  allLanguages?: LanguageItem[]
  allMentionFormats?: MentionFormatItem[]
  allSources?: AnalyticsFetchSentimentAnalysisResponseDataSourceItem[]
  isLost?: AnalyticsFetchThreadsIsLostType
  onClose: () => void
  onSave: (params: {
    isLost: AnalyticsFetchThreadsIsLostType
    selectedKeywords: string[]
    selectedLanguages: string[]
    selectedMentionFormats: string[]
    selectedSources: string[]
  }) => void
  selectedKeywords?: string[]
  selectedLanguages?: string[]
  selectedMentionFormats?: string[]
  selectedSources?: string[]
}

export const FilterModalContent: FC<FilterModalContentProps> = ({
  onSave,
  onClose,
  allKeywords,
  allSources,
  selectedKeywords,
  selectedSources,
  allLanguages,
  allMentionFormats,
  selectedMentionFormats,
  isLost,
  //   mentionFormat,
  selectedLanguages,
}) => {
  const keywordsToOption = (
    keywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  ) => {
    return (
      keywords?.map((keywordItem) => ({
        label: keywordItem.keyword || '',
        labelAddition: keywordItem.total && (
          <MentionItem totalMentions={keywordItem.total} />
        ),
        value: keywordItem.keyword || '',
      })) || []
    )
  }

  const sourcesToOption = (
    sources?: AnalyticsFetchSentimentAnalysisResponseDataSourceItem[]
  ) => {
    return (
      sources?.map((sourceItem) => ({
        label: sourceItem.source ? capitalizeWords(sourceItem.source) : '',
        labelAddition: sourceItem.total && (
          <MentionItem totalMentions={sourceItem.total} />
        ),
        value: sourceItem.source || '',
      })) || []
    )
  }

  const languagesToOptions = (languages?: LanguageItem[]) => {
    return (
      languages?.map((languageItem) => ({
        label: languageItem.language || '',
        labelAddition: languageItem.total && (
          <MentionItem totalMentions={languageItem.total} />
        ),
        value: languageItem.language || '',
      })) || []
    )
  }

  const mentionFormatsToOptions = (mentionFormats?: MentionFormatItem[]) => {
    return (
      mentionFormats?.map((mentionFormatItem) => ({
        label: mentionFormatItem.format
          ? capitalizeFirstLetter(mentionFormatItem.format)
          : '',
        labelAddition: mentionFormatItem.total && (
          <MentionItem totalMentions={mentionFormatItem.total} />
        ),
        value: mentionFormatItem.format || '',
      })) || []
    )
  }

  const optionToValue = (options?: FilterDropdownSelectOption[]) => {
    return options?.map((option) => option.value) || []
  }

  const mappedSelectedKeywords = keywordsToOption(
    allKeywords?.filter(
      (keywordItem) =>
        keywordItem.keyword && selectedKeywords?.includes(keywordItem.keyword)
    ) || []
  )

  const mappedSelectedSources = sourcesToOption(
    allSources?.filter(
      (sourceItem) =>
        sourceItem.source && selectedSources?.includes(sourceItem.source)
    ) || []
  )

  const mappedSelectedLanguages = languagesToOptions(
    allLanguages?.filter(
      (languageItem) =>
        languageItem.language &&
        selectedLanguages?.includes(languageItem.language)
    ) || []
  )

  const mappedSelectedMentionFormats = mentionFormatsToOptions(
    allMentionFormats?.filter(
      (mentionFormatItem) =>
        mentionFormatItem.format &&
        selectedMentionFormats?.includes(mentionFormatItem.format)
    ) || []
  )

  const [selectedKeywordsOptions, setSelectedKeywordsOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedKeywords)

  const [selectedSourcesOptions, setSelectedSourcesOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedSources)

  const [selectedLanguagesOptions, setSelectedLanguagesOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedLanguages)

  const [selectedMentionFormatsOptions, setSelectedMentionFormatsOptions] =
    useState<FilterDropdownSelectOption[]>(mappedSelectedMentionFormats)

  const [selectedLostOptions, setSelectedLostOptions] =
    useState<AnalyticsFetchThreadsIsLostType>(
      isLost !== undefined ? isLost : AnalyticsFetchThreadsIsLostType.Both
    )

  const [isLostChecked, setIsLostChecked] = useState(
    isLost === AnalyticsFetchThreadsIsLostType.Both ||
      isLost === AnalyticsFetchThreadsIsLostType.True
  )
  const [isNotLostChecked, setIsNotLostChecked] = useState(
    isLost === AnalyticsFetchThreadsIsLostType.Both ||
      isLost === AnalyticsFetchThreadsIsLostType.False
  )

  const handleCheckBoxToggle = (
    name:
      | AnalyticsFetchThreadsIsLostType.True
      | AnalyticsFetchThreadsIsLostType.False,
    checked: boolean
  ) => {
    let currentIsLostChecked = isLostChecked
    let currentIsNotLostChecked = isNotLostChecked
    if (name === AnalyticsFetchThreadsIsLostType.True) {
      setIsLostChecked(checked)
      currentIsLostChecked = checked
    } else {
      setIsNotLostChecked(checked)
      currentIsNotLostChecked = checked
    }

    if (currentIsLostChecked && currentIsNotLostChecked) {
      setSelectedLostOptions(AnalyticsFetchThreadsIsLostType.Both)
    } else if (currentIsLostChecked) {
      setSelectedLostOptions(AnalyticsFetchThreadsIsLostType.True)
    } else if (currentIsNotLostChecked) {
      setSelectedLostOptions(AnalyticsFetchThreadsIsLostType.False)
    } else {
      setSelectedLostOptions(AnalyticsFetchThreadsIsLostType.False)
    }
  }

  const keywordOptions = keywordsToOption(allKeywords)
  const sourceOptions = sourcesToOption(allSources)
  const languageOptions = languagesToOptions(allLanguages)
  const mentionFormatOptions = mentionFormatsToOptions(allMentionFormats)

  const handleReset = () => {
    setSelectedKeywordsOptions(keywordOptions)
    setSelectedSourcesOptions(sourceOptions)
    setSelectedLanguagesOptions(languageOptions)
    setSelectedMentionFormatsOptions(mentionFormatOptions)
    setSelectedLostOptions(AnalyticsFetchThreadsIsLostType.Both)
  }

  useEffect(() => {
    setSelectedKeywordsOptions(mappedSelectedKeywords)
  }, [selectedKeywords?.toString()])

  useEffect(() => {
    setSelectedSourcesOptions(mappedSelectedSources)
  }, [selectedSources?.toString()])

  useEffect(() => {
    setSelectedLanguagesOptions(mappedSelectedLanguages)
  }, [selectedLanguages?.toString()])

  useEffect(() => {
    setSelectedMentionFormatsOptions(mappedSelectedMentionFormats)
  }, [selectedMentionFormats?.toString()])

  useEffect(() => {
    setSelectedLostOptions(isLost || AnalyticsFetchThreadsIsLostType.Both)
  }, [isLost])

  return (
    <FilterModalContentWrapper
      onClose={() => onClose()}
      onReset={() => handleReset()}
      onSave={() =>
        onSave({
          isLost: selectedLostOptions,
          selectedKeywords: optionToValue(selectedKeywordsOptions),
          selectedLanguages: optionToValue(selectedLanguagesOptions),
          selectedMentionFormats: optionToValue(selectedMentionFormatsOptions),
          selectedSources: optionToValue(selectedSourcesOptions),
        })
      }
    >
      <div className={styles.filterModalContent}>
        <FilterSearchDropdownSection
          countLabel={en.sources}
          isOpen
          onSelect={setSelectedSourcesOptions}
          options={sourceOptions}
          placeholder={en.sources}
          selectedOptions={selectedSourcesOptions}
        />

        <FilterSearchDropdownSection
          countLabel={en.mentionFormat}
          onSelect={setSelectedMentionFormatsOptions}
          options={mentionFormatOptions}
          placeholder={en.mentionFormat}
          selectedOptions={selectedMentionFormatsOptions}
        />
        <FilterSearchDropdownSection
          countLabel={en.languages}
          onSelect={setSelectedLanguagesOptions}
          options={languageOptions}
          placeholder={en.languages}
          selectedOptions={selectedLanguagesOptions}
        />

        <FilterSearchDropdownSection
          countLabel={en.keywords}
          onSelect={setSelectedKeywordsOptions}
          options={keywordOptions}
          placeholder={en.keywords}
          selectedOptions={selectedKeywordsOptions}
        />
        <div className="h-[1px] w-full" />
        <div className="mt-6 h-[1px] w-full bg-[#EEF1FE]" />
        <div className="mt-6 flex justify-between">
          <span className="font-medium">
            <LinkSvg className="mr-1 inline" />
            URL Types to include
          </span>
          <div className="flex gap-3">
            <div>
              <CheckBox
                isChecked={isNotLostChecked}
                onChange={(e) =>
                  handleCheckBoxToggle(AnalyticsFetchThreadsIsLostType.False, e)
                }
              />
              <span className="pl-2">Functional</span>
            </div>
            <div className="h-full w-[1px] bg-[#EEF1FE]" />
            <div>
              <CheckBox
                isChecked={isLostChecked}
                onChange={(e) =>
                  handleCheckBoxToggle(AnalyticsFetchThreadsIsLostType.True, e)
                }
              />
              <span className="pl-2">Lost</span>
            </div>
          </div>
        </div>
        <div className="mt-6 h-[1px] w-full bg-[#EEF1FE]" />
      </div>
    </FilterModalContentWrapper>
  )
}
