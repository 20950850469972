/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface RelevancyParams {
  /** @example "asd" */
  keyword?: string
  negatives?: string[]
  positives?: string[]
}

export interface RelevancyResultSuggestionItem {
  /** @example "asd" */
  change?: string
  /** @example "asd" */
  from?: string
  /** @example "asd" */
  to?: string
}

export interface RelevancyResultSuggestions {
  negative?: RelevancyResultSuggestionItem[]
  positive?: RelevancyResultSuggestionItem[]
}

export interface RelevancyResultRelevancy {
  /** @example "asd" */
  change?: string
  /** @example "asd" */
  total?: string
}

export interface RelevancyResult {
  /** @example true */
  success?: boolean
  relevancy?: RelevancyResultRelevancy
  suggestions?: RelevancyResultSuggestions
}

export interface SuggestionsCategories {
  result?: string[]
}

export interface SuggestionsSuggestWord {
  /** @example "asd" */
  suggested_word?: string
}

export interface SuggestionsSuperlativesComparativesItem {
  added?: string[]
  group?: string[]
}

export interface SuggestionsSuperlativesComparatives {
  negatives?: SuggestionsSuperlativesComparativesItem[]
  positives?: SuggestionsSuperlativesComparativesItem[]
}

export interface SuggestionsSynonymsAntonyms {
  antonyms?: string[]
  synonyms?: string[]
}

export enum AdminFetchThreadsSentimentType {
  POS = 'POS',
  NEG = 'NEG',
  NEU = 'NEU',
}

export enum AdminFetchThreadsVectorType {
  Desc = 'desc',
  Asc = 'asc',
}

export interface AdminFetchThreadsSupervisedSentiment {
  /** @example ["good"] */
  chatched_positive_keywords?: string[]
  /** @example [] */
  chatched_negative_keywords?: string[]
  /** @example 1 */
  positive_count?: number
  /** @example 0 */
  negative_count?: number
  /** @example 1 */
  positivity_score?: number
  /** @example "POS" */
  output?: string
}

export interface AdminFetchThreadsResponseDataItem {
  /** @example "1234567890abcdef12345678" */
  _id?: string
  /** @example "638483bf7c37f93cf946d870" */
  campaign?: string
  /** @example "England on Reddit" */
  campaign_name?: string
  /** @example "Bellingham" */
  keyword?: string
  /** @example "https://reddit.com/r/FIFA/comments/10k75ac/i_packed_bellingham_what_is_the_best_chem_i_can/" */
  origin?: string
  /** @example "https://reddit.com/r/FIFA/comments/10k75ac/i_packed_bellingham_what_is_the_best_chem_i_can/" */
  link?: string
  /** @example "reddit" */
  source?: string
  /** @example "I packed Bellingham what is the best chem I can put on him." */
  text?: string
  /** @example "2023-01-24T14:49:09.000Z" */
  published_at?: string
  /** @example false */
  irrelevant?: boolean
  /** @example "POS" */
  output?: string
  /** @example ["good"] */
  positive_keywords?: string[]
  /** @example [] */
  negative_keywords?: string[]
  supervised_sentiment?: AdminFetchThreadsSupervisedSentiment
}

export interface AdminFetchThreadsResponse {
  /** @example true */
  success?: boolean
  data?: AdminFetchThreadsResponseDataItem[]
  selectedSources?: string[]
  selectedKeywords?: string[]
  allSources?: string[]
  allKeywords?: string[]
}

export interface AnalyticsFetchCalendarDetailsResponseDataSentiments {
  /** @example 0 */
  negPercentage?: number
  /** @example 13 */
  negatives?: number
  /** @example 100 */
  neuPercentage?: number
  /** @example 10944 */
  neutrals?: number
  /** @example 0 */
  posPercentage?: number
  /** @example 34 */
  positives?: number
  /** @example 10991 */
  total?: number
}

export interface AnalyticsFetchCalendarDetailsResponse {
  result?: AnalyticsFetchCalendarDetailsResponseDataSentiments
  topCampaigns?: string[]
  topSources?: string[]
}

export enum FetchThreadsCSVSentimentType {
  POS = 'POS',
  NEG = 'NEG',
  NEU = 'NEU',
}

export enum FetchThreadsCSVIsLostType {
  True = 'true',
  False = 'false',
  Both = 'both',
}

export interface FetchThreadsCSVSupervisedSentiment {
  /** @example ["good"] */
  chatched_positive_keywords?: string[]
  /** @example [] */
  chatched_negative_keywords?: string[]
  /** @example 1 */
  positive_count?: number
  /** @example 0 */
  negative_count?: number
  /** @example 1 */
  positivity_score?: number
  /** @example "POS" */
  output?: string
}

/**
 * @maxItems 4
 * @minItems 4
 */
export type AnalyticsFetchCampaignsCalendarResponseSentiments = number[]

export interface AnalyticsFetchCampaignsCalendarResponse {
  /** @example [[0,0,14,0],[0,1,9,0],[0,2,94,0],[0,3,66,0]] */
  sentiments?: AnalyticsFetchCampaignsCalendarResponseSentiments[]
  /** @example ["12:00 AM","3:00 AM","6:00 AM","9:00 AM","12:00 PM","15:00 PM","18:00 PM","21:00 PM","23:00 PM"] */
  xAxis?: string[]
  /** @example ["SAT","FRI","THU","WED","TUE","MON","SUN"] */
  yAxis?: string[]
}

/** @example "last 7 days" */
export enum AnalyticsFetchCustomCalendarRangeDefaultRangeType {
  Last30Days = 'last 30 days',
  Last7Day = 'last 7 day',
  Today = 'today',
}

export interface AnalyticsFetchCustomCalendarRangeResponse {
  /** @example "2023-02-16T10:03:01.032Z" */
  firstDate?: string
  /** @example "2023-03-15T00:00:00.000Z" */
  lastDate?: string
  defaultRange?: AnalyticsFetchCustomCalendarRangeDefaultRangeType
}

export interface AnalyticsFetchDailyTrendsResponse {
  /** @example ["2023-03-12","2023-03-13","2023-03-14"] */
  dates?: string[]
  /** @example [2,4,5] */
  positives?: number[]
  /** @example [671,758,649] */
  neutrals?: number[]
  /** @example [5,10,10] */
  negatives?: number[]
  /** @example [678,772,664] */
  total?: number[]
}

export interface AnalyticsFetchHomeCalendarDateItem {
  /**
   * @maxItems 2
   * @minItems 2
   */
  value?: (string | number)[]
}

export interface AnalyticsFetchHomeCalendarChartDataPositivityDetails {
  /** @example 152 */
  positives?: number
  /** @example 27 */
  negatives?: number
  /** @example 12585 */
  neutrals?: number
  /** @example 0.0912912912912913 */
  opacity?: number
}

export interface AnalyticsFetchHomeCalendarChartDataPositivity {
  value?: AnalyticsFetchHomeCalendarChartDataPositivityDetails[]
}

export interface AnalyticsFetchHomeCalendarResponse {
  /** @example "2023-03-01" */
  first?: string
  /** @example "2023-03-15" */
  last?: string
  /** @example "2023-03" */
  range?: string
  /** @example "March 2023" */
  title?: string
  /** @example "2023-23-14" */
  today?: string
  datesBefore?: AnalyticsFetchHomeCalendarDateItem[]
  datesAfter?: AnalyticsFetchHomeCalendarDateItem[]
  campaignDates?: AnalyticsFetchHomeCalendarDateItem[]
  daysOfMonth?: AnalyticsFetchHomeCalendarDateItem[]
  negativeData?: AnalyticsFetchHomeCalendarChartDataPositivity[]
  positiveData?: AnalyticsFetchHomeCalendarChartDataPositivity[]
}

export interface AnalyticsFetchHotLinksResponseItem {
  /** @example "Opinions: War in Ukraine" */
  campaign?: string
  /** @example "Netanyahu" */
  keyword?: string
  /** @example "https://twitter.com/17061263/status/1635274782076981249" */
  origin?: string
  /** @example "dunamisfire217" */
  origin_name?: string
  /** @example "dunamisfire217" */
  name?: string
  /** @example "twitter" */
  source?: string
  /** @example "2023-03-13T23:59:59.999Z" */
  date?: string
  /** @example 0 */
  positives?: number
  /** @example 0 */
  negatives?: number
  /** @example 33 */
  neutrals?: number
  /** @example 33 */
  total?: number
  /** @example 0 */
  positivePerc?: number
  /** @example 0 */
  negativePerc?: number
  /** @example 100 */
  neutralPerc?: number
  links?: string[]
}

export type AnalyticsFetchHotLinksResponse =
  AnalyticsFetchHotLinksResponseItem[]

export interface AnalyticsKeywordsAnalysisResponseItem {
  /** @example "Atlassian" */
  keyword?: string
  /** @example 10 */
  positives?: number
  /** @example 10 */
  negatives?: number
  /** @example 30 */
  neutrals?: number
  /** @example 50 */
  total?: number
  /** @example 20 */
  positivePercentage?: number
  /** @example 20 */
  negativePercentage?: number
  /** @example 60 */
  neutralPercentage?: number
  /** @example "Neutral" */
  mostlyRated?: string
}

export interface AnalyticsKeywordsAnalysisResponse {
  /** @example true */
  success?: boolean
  sentiments?: AnalyticsKeywordsAnalysisResponseItem[]
  selectedKeywords?: string[]
  positive?: number
  negative?: number
  neutral?: number
  allKeywords?: AnalyticsKeywordsAnalysisResponseItem[]
}

export interface AnalyticsFetchLocationsResponseRegionCountry {
  /** @example "sweden" */
  name?: string
  /** @example 4 */
  total?: number
}

export interface AnalyticsFetchLocationsResponseItemValue {
  /** @example "northern europe" */
  name?: string
  /** @example 1 */
  positives?: number
  /** @example 1 */
  negatives?: number
  /** @example 23 */
  neutrals?: number
  /** @example 25 */
  total?: number
  /** @example "POS" */
  mostlyRatedAs?: string
  /** @example 62.153637 */
  lat?: number
  /** @example 16.908783 */
  lng?: number
  countries?: AnalyticsFetchLocationsResponseRegionCountry[]
}

export interface AnalyticsFetchLocationsResponse {
  /** @example 211 */
  totalMentions?: number
  locations?: Record<string, AnalyticsFetchLocationsResponseItemValue>
}

export interface AnalyticsFetchMostMentionsResponseItem {
  /** @example "63f3841772f56a167fb29f3e" */
  campaign?: string
  /** @example "Car brands from Germany" */
  name?: string
  /** @example 1403 */
  mentions?: number
  /** @example 5 */
  positives?: number
  /** @example 3 */
  negatives?: number
  /** @example 1395 */
  neutrals?: number
  /** @example 13 */
  percentage?: number
}

export interface AnalyticsFetchMostMentionsResponse {
  result?: AnalyticsFetchMostMentionsResponseItem[]
}

export interface AnalyticsFetchPositivityPercentResponse {
  /** @example 10991 */
  mentions?: number
  /** @example 0.31 */
  positivePerc?: number
  /** @example 0.12 */
  negativePerc?: number
  /** @example 99.57 */
  neutralPerc?: number
}

export interface AnalyticsFetchQuickDetailsResponseItemTopSource {
  /**
   * Source platform of the mentions
   * @example "twitter"
   */
  source?: string
  /**
   * Total number of mentions from this source
   * @example 3339
   */
  total?: number
}

export interface AnalyticsFetchQuickDetailsResponseItem {
  /**
   * Unique identifier of the campaign
   * @example "63edff558461ed8014705982"
   */
  campaign?: string
  /**
   * Name of the campaign
   * @example "Opinions: War in Ukraine"
   */
  name?: string
  /**
   * Count of positive mentions
   * @example 0
   */
  positives?: number
  /**
   * Count of negative mentions
   * @example 0
   */
  negatives?: number
  /**
   * Count of neutral mentions
   * @example 8424
   */
  neutrals?: number
  /**
   * Total number of mentions
   * @example 8424
   */
  total?: number
  /**
   * Percentage of positive mentions
   * @example 0
   */
  positivePerc?: number
  /**
   * Percentage of negative mentions
   * @example 0
   */
  negativePerc?: number
  /**
   * Percentage of neutral mentions
   * @example 100
   */
  neutralPerc?: number
  /** List of sources and their mention counts */
  topSources?: AnalyticsFetchQuickDetailsResponseItemTopSource[]
}

/** Array of campaign analytics data, sorted by total mentions in descending order */
export type AnalyticsFetchQuickDetailsResponse =
  AnalyticsFetchQuickDetailsResponseItem[]

export interface AnalyticsFetchRadarChartResponseItem {
  /** @example "Analyse: Atlassian" */
  campaign?: string
  /** @example 5 */
  positives?: number
  /** @example 10 */
  negatives?: number
  /** @example 15 */
  total?: number
}

export interface AnalyticsFetchRadarChartResponse {
  result?: AnalyticsFetchRadarChartResponseItem[]
}

export enum AnalyticsFetchThreadsIsLostType {
  True = 'true',
  False = 'false',
  Both = 'both',
}

export interface LanguageItem {
  /** @example "English" */
  language?: string
  /** @example 99 */
  total?: number
}

export interface MentionFormatItem {
  /** @example "hashtag" */
  format?: 'hashtag' | 'keyword'
  /** @example 99 */
  total?: number
}

export interface AnalyticsFetchSentimentAnalysisResponseDataSourceItem {
  /** @example "facebook" */
  source?: string
  /** @example 1 */
  positives?: number
  /** @example 0 */
  negatives?: number
  /** @example 5 */
  neutrals?: number
  /** @example 6 */
  total?: number
  /** @example 16.67 */
  positivePercentage?: number
  /** @example 0 */
  negativePercentage?: number
  /** @example 83.33 */
  neutralPercentage?: number
}

export interface AnalyticsFetchSentimentAnalysisResponseDataKeywordItem {
  /** @example "Messi" */
  keyword?: string
  /** @example "651a95db66f56e2e327ed4b6" */
  campaign?: string
  /** @example "AzerGold " */
  campaign_name?: string
  /** @example 0 */
  positives?: number
  /** @example 0 */
  negatives?: number
  /** @example 1 */
  neutrals?: number
  /** @example 1 */
  total?: number
  /** @example 0 */
  positivePercentage?: number
  /** @example 0 */
  negativePercentage?: number
  /** @example 100 */
  neutralPercentage?: number
}

export interface AnalyticsFetchSentimentAnalysisResponseData {
  /** @example 5 */
  positives?: number
  /** @example 10 */
  negatives?: number
  /** @example 649 */
  neutrals?: number
  /** @example 0.75 */
  positivePercentage?: number
  /** @example 1.51 */
  negativePercentage?: number
  /** @example 97.74 */
  neutralPercentage?: number
  allSources?: AnalyticsFetchSentimentAnalysisResponseDataSourceItem[]
  allKeywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  /** @example ["facebook"] */
  selectedSources?: string[]
  /** @example ["Messi"] */
  selectedKeywords?: string[]
  /** @example [{"language":"English","total":99},{"language":"Spanish","total":99},{"language":"French","total":99}] */
  allLanguages?: LanguageItem[]
  /** @example ["English","Spanish"] */
  checkedLanguages?: string[]
  /** @example [{"format":"hashtag","total":99},{"format":"keyword","total":99}] */
  allMentionsFormat?: MentionFormatItem[]
  /** @example ["hashtag","keyword"] */
  selectedMentionFormat?: string[]
  isLost?: AnalyticsFetchThreadsIsLostType
}

export interface AnalyticsFetchSentimentAnalysisResponse {
  /** @example true */
  success?: boolean
  data?: AnalyticsFetchSentimentAnalysisResponseData
}

export interface AnalyticsSentimentChartAxisData {
  min?: number
  max?: number
  interval?: number
}

export interface AnalyticsFetchSentimentChartResponseDataItem {
  /** @example "twitter" */
  source?: string
  /** @example [[1678748400000,-2],[1678773600000,-1],[1678784400000,1],[1678788000000,-1]] */
  data?: number[][]
}

export interface AnalyticsFetchSentimentChartResponse {
  /** @example {"min":1678737600000,"max":1678823999999,"interval":3600000} */
  XAxis?: AnalyticsSentimentChartAxisData
  /** @example {"min":-20,"max":20} */
  YAxis?: AnalyticsSentimentChartAxisData
  /** @example [] */
  allCampaigns?: string[]
  /** @example ["Trello","Bitbucket","Confluence","Jira"] */
  allKeywords?: string[]
  /** @example ["Twitter","Reddit","Youtube","news & blogs"] */
  allSources?: string[]
  /** @example [] */
  checkedCampaigns?: string[]
  /** @example ["Trello","Bitbucket"] */
  checkedKeywords?: string[]
  /** @example ["reddit","twitter"] */
  checkedSources?: string[]
  data?: AnalyticsFetchSentimentChartResponseDataItem[]
}

export enum AnalyticsFetchThreadsSentimentType {
  POS = 'POS',
  NEG = 'NEG',
  NEU = 'NEU',
}

export enum AnalyticsFetchThreadsVectorType {
  Desc = 'desc',
  Asc = 'asc',
}

export interface AnalyticsFetchThreadsSupervisedSentiment {
  /** @example ["good"] */
  chatched_positive_keywords?: string[]
  /** @example [] */
  chatched_negative_keywords?: string[]
  /** @example 1 */
  positive_count?: number
  /** @example 0 */
  negative_count?: number
  /** @example 1 */
  positivity_score?: number
  /** @example "POS" */
  output?: string
}

export interface AnalyticsFetchThreadsResponseDataItem {
  /** @example "1234567890abcdef12345678" */
  _id?: string
  /** @example "638483bf7c37f93cf946d870" */
  campaign?: string
  /** @example "England on Reddit" */
  campaign_name?: string
  /** @example "Bellingham" */
  keyword?: string
  /** @example "https://reddit.com/r/FIFA/comments/10k75ac/i_packed_bellingham_what_is_the_best_chem_i_can/" */
  origin?: string
  /** @example "https://reddit.com/r/FIFA/comments/10k75ac/i_packed_bellingham_what_is_the_best_chem_i_can/" */
  link?: string
  /** @example "reddit" */
  source?: string
  /** @example 5 */
  score?: number
  /** @example "I packed Bellingham what is the best chem I can put on him." */
  text?: string
  /** @example "2023-01-24T14:49:09.000Z" */
  published_at?: string
  /** @example "POS" */
  output?: string
  /** @example ["good"] */
  positive_keywords?: string[]
  /** @example [] */
  negative_keywords?: string[]
  /** @example "english" */
  language?: string
  /** @example "en" */
  original_lang_code?: string
  /** @example false */
  irrelevant?: boolean
  /** @example "I packed Bellingham what is the best chem I can put on him." */
  translated?: string
  supervised_sentiment?: AnalyticsFetchThreadsSupervisedSentiment
}

export interface AnalyticsFetchThreadsResponse {
  /** @example true */
  success?: boolean
  data?: AnalyticsFetchThreadsResponseDataItem[]
  selectedSources?: string[]
  selectedKeywords?: string[]
  allSources?: string[]
  allKeywords?: string[]
  allLanguages?: string[]
  checkedLanguages?: string[]
}

export enum FetchThreadsCSVVectorType {
  Desc = 'desc',
  Asc = 'asc',
}

export interface AnalyticsFetchTopSourcesResponseItem {
  /** @example "twitter" */
  name?: string
  /** @example 5 */
  positives?: number
  /** @example 10 */
  negatives?: number
  /** @example 649 */
  neutrals?: number
  /** @example 664 */
  total?: number
  /** @example 0.75 */
  positivePercentage?: number
  /** @example 1.51 */
  negativePercentage?: number
  /** @example 97.74 */
  neutralPercentage?: number
}

export type AnalyticsFetchTopSourcesResponse =
  AnalyticsFetchTopSourcesResponseItem[]

export interface AnalyticsFetchTopKeywordsResponseStats {
  /** @example 0 */
  avgDailyNegativeMentionCount?: number
  /** @example 1 */
  avgDailyNegativeMentionCountChange?: number
  /** @example 1 */
  avgDailyPositiveMentionCount?: number
  /** @example 1 */
  avgDailyPositiveMentionCountChange?: number
  /** @example 186 */
  avgMentionPerKeyword?: number
}

export interface AnalyticsFetchTopKeywordsResponseTopKeyword {
  /** @example "Biden" */
  name?: string
  /** @example 27 */
  positives?: number
  /** @example 34 */
  negatives?: number
  /** @example 2414 */
  mentionsCount?: number
}

export interface AnalyticsFetchTopKeywordsResponse {
  stats?: AnalyticsFetchTopKeywordsResponseStats
  topKeywords?: AnalyticsFetchTopKeywordsResponseTopKeyword[]
}

export interface CampaignCategory {
  categories?: string[]
  /** @example true */
  success?: boolean
}

export interface DailyPositivityCampaignResponse {
  /** @example 0.1 */
  changePercentage?: number
  /** @example 100 */
  mentions?: number
  /** @example "asd" */
  name?: string
}

export interface CampaignFetchTopLocationData {
  /** @example "asd" */
  country?: string
  /** @example 0.1 */
  lat?: number
  /** @example 0.1 */
  lng?: number
  /** @example 100 */
  mentions?: number
}

export interface CampaignFetchTopSourceData {
  /** @example 100 */
  mentions?: number
  /** @example 0.1 */
  negativePercentage?: number
  /** @example 0.1 */
  neutralPercentage?: number
  /** @example 0.1 */
  positivePercentage?: number
  /** @example "asd" */
  source?: string
}

export interface CampaignPayloadKeyword {
  categories?: string[]
  /** @example "asd" */
  name?: string
  negative?: string[]
  positive?: string[]
}

export interface CampaignStatsTopSource {
  /** @example "asd" */
  source?: string
  /** @example 13 */
  mentions?: number
}

export interface CampaignStatsTopKeyword {
  /** @example "asd" */
  keyword?: string
  /** @example 13 */
  mentions?: number
}

export interface CampaignKeyword {
  /** @example "asd" */
  _id?: string
  /** @example "asd" */
  name?: string
  negative?: string[]
  positive?: string[]
}

export interface CampaignStats {
  /** @example 13 */
  keywordsCount?: number
  /** @example 13 */
  mentions?: number
  /** @example 13 */
  negativeMentionsCount?: number
  /** @example "asd" */
  negativeRatio?: string
  /** @example 13 */
  neutralMentionsCount?: number
  /** @example "asd" */
  neutralRatio?: string
  /** @example 13 */
  positiveMentionsCount?: number
  /** @example "asd" */
  positiveRatio?: string
  topSources?: CampaignStatsTopSource[]
  topKeywords?: CampaignStatsTopKeyword[]
}

export interface CampaignType {
  /** @example "asd" */
  _id?: string
  categories?: string[]
  /** @example "asd" */
  createdAt?: string
  /** @example "asd" */
  from?: string
  keywords?: CampaignKeyword[]
  /** @example "asd" */
  name?: string
  stats?: CampaignStats
  /** @example "asd" */
  status?: string
  tags?: string[]
  targets?: string[]
  facebook_target_endpoints?: string[]
  instagram_target_endpoints?: string[]
  /** @example 13 */
  timezone?: number
  /** @example "asd" */
  timezoneName?: string
  /** @example "asd" */
  to?: string
  /** @example "asd" */
  updatedAt?: string
  /** @example "asd" */
  user?: string
}

export interface CampaignPayload {
  deletedKeyWords?: string[]
  keywords?: CampaignPayloadKeyword[]
  facebook_target_endpoints?: string[]
  /** @example "asd" */
  from?: string
  /** @example "asd" */
  to?: string
  instagram_target_endpoints?: string[]
  /** @example "asd" */
  name?: string
  /** @example "asd" */
  status?: string
  tags?: string[]
  targets?: string[]
  /** @example 13 */
  timezone?: number
  /** @example "asd" */
  timezoneName?: string
}

export interface CampaignResponse {
  campaign?: CampaignType
  /** @example true */
  success?: boolean
}

export interface FetchCampaignByIdResponse {
  campaign?: CampaignType
  /** @example true */
  success?: boolean
}

export interface FetchCampaignsResponse {
  campaigns?: CampaignType[]
  /** @example true */
  success?: boolean
}

export interface UpdateCampaignResponse {
  campaign?: CampaignType
  /** @example true */
  success?: boolean
}

export interface CampaignDelete {
  /** @example true */
  success?: boolean
}

export interface CampaignTargetResponse {
  /** @example true */
  success?: boolean
  targets?: string[]
}

export interface KeywordFetchDailyTrends {
  /** @example ["2023-03-12","2023-03-13","2023-03-14"] */
  dates?: string[]
  /** @example [2,4,5] */
  positives?: number[]
  /** @example [671,758,649] */
  neutrals?: number[]
  /** @example [5,10,10] */
  negatives?: number[]
  /** @example [678,772,664] */
  total?: number[]
}

export interface KeywordFetchKeywordsWithStatsTopSource {
  /** @example "twitter" */
  _id?: string
  /** @example 13 */
  count?: number
}

export interface KeywordFetchKeywordsWithStats {
  topSources?: KeywordFetchKeywordsWithStatsTopSource[]
  /** @example "active" */
  activeStatus?: string
  /** @example "React" */
  campaign?: string
  /** @example "63f0d4deb64d852011735791" */
  campaignID?: string
  /** @example "2023-02-20T11:37:15.868Z" */
  createdAt?: string
  /** @example "2023-02-20T11:37:15.868Z" */
  from?: string
  /** @example "2023-02-20T11:37:15.868Z" */
  to?: string
  /** @example "2023-02-20T11:37:15.868Z" */
  updatedAt?: string
  /** @example 10 */
  mentions?: number
  /** @example "React Course" */
  name?: string
  /** @example 10 */
  negativeCount?: number
  /** @example 10 */
  negativityPercentage?: number
  /** @example 10 */
  neutralCount?: number
  /** @example 10 */
  neutralityPercentage?: number
  /** @example 10 */
  positiveCount?: number
  /** @example 10 */
  positivityPercentage?: number
}

export interface KeywordFetchSentimentAnalysis {
  /** @example 10 */
  negativeMentionsCount?: number
  /** @example 10 */
  negativePercentage?: number
  /** @example 10 */
  neutralMentionsCount?: number
  /** @example 10 */
  neutralPercentage?: number
  /** @example 10 */
  positiveMentionsCount?: number
  /** @example 10 */
  positivePercentage?: number
}

export interface KeywordFetchSentimentChartAxisData {
  /** @example 10 */
  interval?: number
  /** @example 10 */
  max?: number
  /** @example 10 */
  min?: number
}

export interface KeywordFetchSentimentChartSource {
  /** @example [[10,10],[10,10]] */
  data?: [number][]
  /** @example ["twitter"] */
  source?: string
}

export interface KeywordFetchSentimentChart {
  XAxis?: KeywordFetchSentimentChartAxisData
  YAxis?: KeywordFetchSentimentChartAxisData
  data?: KeywordFetchSentimentChartSource[]
  allCampaigns?: string[]
  checkedCampaigns?: string[]
  allSources?: string[]
  checkedSources?: string[]
  allKeywords?: string[]
  checkedKeywords?: string[]
}

export interface KeywordFetchTopSources {
  /** @example "twitter" */
  name?: string
  /** @example 0 */
  positivePercentage?: number
  /** @example 0 */
  positives?: number
  /** @example 0 */
  negativePercentage?: number
  /** @example 0 */
  negatives?: number
  /** @example 100 */
  neutralPercentage?: number
  /** @example 4 */
  neutrals?: number
  /** @example 4 */
  total?: number
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    )
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title TripAdvisor Search API
 * @version 1.0.0
 *
 * API endpoint to change sentiment of multiple threads
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * No description
     *
     * @tags Relevancy
     * @name RelevancyCreate
     * @summary Get relevancy based on words
     * @request POST:/v1/relevancy
     */
    relevancyCreate: (data: any, params: RequestParams = {}) =>
      this.request<RelevancyResult, any>({
        path: `/v1/relevancy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Suggestions
     * @name SuggestionsCategoriesList
     * @summary Get suggestion categories based on word
     * @request GET:/v1/suggestions/categories
     */
    suggestionsCategoriesList: (
      query?: {
        /**
         * Word to generate suggestions
         * @example "asd"
         */
        word?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestionsCategories, any>({
        path: `/v1/suggestions/categories`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Suggestions
     * @name SuggestionsSuggestWordList
     * @summary Get suggestions based on word
     * @request GET:/v1/suggestions/suggest-word
     */
    suggestionsSuggestWordList: (
      query?: {
        /**
         * Word to generate suggestions
         * @example "asd"
         */
        word?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestionsSuggestWord, any>({
        path: `/v1/suggestions/suggest-word`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Suggestions
     * @name SuggestionsSuperlativesComparativesList
     * @summary Get superlatives and comparatives based on words
     * @request GET:/v1/suggestions/superlatives-comparatives
     */
    suggestionsSuperlativesComparativesList: (
      query?: {
        /**
         * Positive word to generate superlatives and comparatives
         * @example "asd,asd,asd"
         */
        positives?: string
        /**
         * Negative word to generate superlatives and comparatives
         * @example "asd,asd,asd"
         */
        negatives?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestionsSuperlativesComparatives, any>({
        path: `/v1/suggestions/superlatives-comparatives`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Suggestions
     * @name SuggestionsSynonymsAntonymsList
     * @summary Get synonyms and antonyms based on word
     * @request GET:/v1/suggestions/synonyms-antonyms
     */
    suggestionsSynonymsAntonymsList: (
      query?: {
        /**
         * Word to generate suggestions
         * @example "asd"
         */
        word?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestionsSynonymsAntonyms, any>({
        path: `/v1/suggestions/synonyms-antonyms`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  v1Api = {
    /**
     * No description
     *
     * @tags Admin
     * @name AdminThreadsList
     * @summary Get Threads filtered by Keyword, Source or Sentiment
     * @request GET:/v1_api/admin/threads
     */
    adminThreadsList: (
      query?: {
        /**
         * Filter by campaign keyword
         * @example "Bellingham"
         */
        keyword?: string
        /**
         * Filter by source, allowed values: twitter, youtube, reddit, news & blogs
         * @example "reddit"
         */
        source?: string
        /**
         * Filter by sentiment, allowed values: POS, NEG, NEU
         * @example "POS"
         */
        sentiment?: AdminFetchThreadsSentimentType
        /**
         * Used in paging together with vector. If specified, returns results before / after the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        lastDate?: string
        /**
         * Used in paging together with lastDate. If specified, sets the sorting order, e.g. desc means descending date order. allowed values: desc, asc
         * @example "desc"
         */
        vector?: AdminFetchThreadsVectorType
        /**
         * Number of items returned in the response
         * @example 10
         */
        limit?: number
        /**
         * Selected sources for threads, seperated by comma
         * @example "Facebook,Instagram"
         */
        selectedSources?: string
        /**
         * Selected keywords for threads, seperated by comma
         * @example "Apple,Microsoft"
         */
        selectedKeywords?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminFetchThreadsResponse, any>({
        path: `/v1_api/admin/threads`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  v1Private = {
    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchCalendarDetailsList
     * @summary Get Calendar Details on specific date
     * @request GET:/v1_private/analytics/fetchCalendarDetails
     */
    analyticsFetchCalendarDetailsList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        date?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchCalendarDetailsResponse, any>({
        path: `/v1_private/analytics/fetchCalendarDetails`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchCampaignExcelList
     * @summary Get Calendar Details on specific date
     * @request GET:/v1_private/analytics/fetchCampaignExcel
     */
    analyticsFetchCampaignExcelList: (
      query?: {
        /**
         * Filter by Campaign
         * @example "campaign-id"
         */
        campaign?: string
        /**
         * Filter by source, allowed values: twitter, youtube, reddit, news & blogs
         * @example "reddit"
         */
        source?: string
        /**
         * Filter by sentiment, allowed values: POS, NEG, NEU
         * @example "POS"
         */
        sentiment?: FetchThreadsCSVSentimentType
        /**
         * Selected sources for threads, seperated by comma
         * @example "Facebook,Instagram"
         */
        selectedSources?: string
        /**
         * Selected keywords for threads, seperated by comma
         * @example "Apple,Microsoft"
         */
        selectedKeywords?: string
        /**
         * Filter by languages, separated by comma
         * @example "english,spanish,french"
         */
        languages?: string
        /**
         * Filter by lost status, allowed values: true, false, both
         * @example "both"
         */
        isLost?: FetchThreadsCSVIsLostType
        /**
         * Filter by mention format, allowed values: keyword, hashtag, both
         * @example "both"
         */
        mentionFormat?: string
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<File, any>({
        path: `/v1_private/analytics/fetchCampaignExcel`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchCampaignsCalendarList
     * @summary Get Calendar of Campaign or Category
     * @request GET:/v1_private/analytics/fetchCampaignsCalendar
     */
    analyticsFetchCampaignsCalendarList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by Campaign ID
         * @example "63ff25da11b15267d928b99c"
         */
        campaign?: string
        /** Filter by Category */
        category?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchCampaignsCalendarResponse, any>({
        path: `/v1_private/analytics/fetchCampaignsCalendar`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchCustomCalendarRangeList
     * @summary Get how long the campaigns have been active as calendar range
     * @request GET:/v1_private/analytics/fetchCustomCalendarRange
     */
    analyticsFetchCustomCalendarRangeList: (params: RequestParams = {}) =>
      this.request<AnalyticsFetchCustomCalendarRangeResponse, any>({
        path: `/v1_private/analytics/fetchCustomCalendarRange`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchDailyTrendsList
     * @summary Get Daily Trends
     * @request GET:/v1_private/analytics/fetchDailyTrends
     */
    analyticsFetchDailyTrendsList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-11T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by Campaign ID
         * @example "63ff25da11b15267d928b99c"
         */
        campaign?: string
        /** Filter by Category */
        category?: string
        /** Filter by Keyword */
        keyword?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchDailyTrendsResponse, any>({
        path: `/v1_private/analytics/fetchDailyTrends`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchHomeCalendarList
     * @summary Get Home Calendar
     * @request GET:/v1_private/analytics/fetchHomeCalendar
     */
    analyticsFetchHomeCalendarList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Current Date
         * @example "2023-03-14"
         */
        currentDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchHomeCalendarResponse, any>({
        path: `/v1_private/analytics/fetchHomeCalendar`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchHotLinksList
     * @summary Get Hot Links
     * @request GET:/v1_private/analytics/fetchHotLinks
     */
    analyticsFetchHotLinksList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchHotLinksResponse, any>({
        path: `/v1_private/analytics/fetchHotLinks`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchKeywordsAnalysisList
     * @summary Get Keywords Analysis on campaigns
     * @request GET:/v1_private/analytics/fetchKeywordsAnalysis
     */
    analyticsFetchKeywordsAnalysisList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /** @example "6508926c66f56e47617ed4a6" */
        campaign?: string
        /**
         * User selected keywords
         * @example "AzerGold,Ogtay Mammadov"
         */
        selectedKeywords?: string
        /**
         * filterCount is a number which user selects
         * @example 10
         */
        filterCount?: string
        /**
         * filterChoice is a string, more or less
         * @example "more"
         */
        filterChoice?: string
        /**
         * sentimentType is a string to filter by sentiment, can be single word e.g positive or comma seperated positive,negative,neutral. if selected All Types sentimentType should be 'total'
         * @example "positives,neutrals"
         */
        sentimentType?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsKeywordsAnalysisResponse, any>({
        path: `/v1_private/analytics/fetchKeywordsAnalysis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchLocationsList
     * @summary Get Locations Data filtered by campaign and zoom level
     * @request GET:/v1_private/analytics/fetchLocations
     */
    analyticsFetchLocationsList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by Campaign ID
         * @example "63ff25da11b15267d928b99c"
         */
        campaign?: string
        /**
         * Zoom level
         * @example "1"
         */
        zoom?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchLocationsResponse, any>({
        path: `/v1_private/analytics/fetchLocations`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchMostMentionsList
     * @summary Get Most Mentions
     * @request GET:/v1_private/analytics/fetchMostMentions
     */
    analyticsFetchMostMentionsList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchMostMentionsResponse, any>({
        path: `/v1_private/analytics/fetchMostMentions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchPositivityPercentList
     * @summary Get Positivity Percent
     * @request GET:/v1_private/analytics/fetchPositivityPercent
     */
    analyticsFetchPositivityPercentList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchPositivityPercentResponse, any>({
        path: `/v1_private/analytics/fetchPositivityPercent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves analytics data for all campaigns within the specified date range
     *
     * @tags Analytics
     * @name AnalyticsFetchQuickDetailsList
     * @summary Get Campaign Analytics Details
     * @request GET:/v1_private/analytics/fetchQuickDetails
     */
    analyticsFetchQuickDetailsList: (
      query?: {
        /**
         * Start date for analytics data
         * @format date-time
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * End date for analytics data
         * @format date-time
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchQuickDetailsResponse, any>({
        path: `/v1_private/analytics/fetchQuickDetails`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchRadarChartList
     * @summary Get Radar Chart
     * @request GET:/v1_private/analytics/fetchRadarChart
     */
    analyticsFetchRadarChartList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchRadarChartResponse, any>({
        path: `/v1_private/analytics/fetchRadarChart`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchSentimentAnalysisList
     * @summary Get Sentiment Analysis filtered by Campaign, Category, Keyword or Source
     * @request GET:/v1_private/analytics/fetchSentimentAnalysis
     */
    analyticsFetchSentimentAnalysisList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-07T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by campaign id
         * @example "63ff25da11b15267d928b99c"
         */
        campaign?: string
        /** Filter by category */
        category?: string
        /**
         * Filter by keyword
         * @example "Bellingham"
         */
        keyword?: string
        /**
         * Filter by source, allowed values: twitter, youtube, reddit, news & blogs
         * @example "reddit"
         */
        source?: string
        /**
         * Selected sources separated by comma
         * @example "Facebook,Instagram"
         */
        selectedSources?: string
        /**
         * Selected keywords separated by comma
         * @example "Bellingham,Messi"
         */
        selectedKeywords?: string
        /**
         * Selected languages separated by comma
         * @example "English,Spanish"
         */
        languages?: string
        /**
         * Filter for lost mentions (values: both, lost, not_lost)
         * @default "both"
         * @example "both"
         */
        isLost?: string
        /**
         * Filter for mention format (values: both, hashtag, keyword)
         * @default "both"
         * @example "both"
         */
        mentionFormat?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchSentimentAnalysisResponse, any>({
        path: `/v1_private/analytics/fetchSentimentAnalysis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchSentimentChartList
     * @summary Get Sentiment Chart filtered by Campaign, Category, Keyword, and Source
     * @request GET:/v1_private/analytics/fetchSentimentChart
     */
    analyticsFetchSentimentChartList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by Campaign ID
         * @example "63ff25da11b15267d928b99c"
         */
        campaign?: string
        /** Filter by Category */
        category?: string
        /**
         * Filter by Keywords (comma separated list)
         * @example "Trello,Bitbucket"
         */
        keywords?: string
        /**
         * Filter by Sources (comma separated list)
         * @example "reddit,twitter"
         */
        sources?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchSentimentChartResponse, any>({
        path: `/v1_private/analytics/fetchSentimentChart`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchThreadsList
     * @summary Get Threads filtered by Campaign, Keyword, Source or Sentiment
     * @request GET:/v1_private/analytics/fetchThreads
     */
    analyticsFetchThreadsList: (
      query?: {
        /**
         * Filter by campaign id
         * @example "638483bf7c37f93cf946d870"
         */
        campaign?: string
        /**
         * Filter by campaign keyword
         * @example "Bellingham"
         */
        keyword?: string
        /**
         * Filter by source, allowed values: twitter, youtube, reddit, news & blogs
         * @example "reddit"
         */
        source?: string
        /**
         * Filter by sentiment, allowed values: POS, NEG, NEU
         * @example "POS"
         */
        sentiment?: AnalyticsFetchThreadsSentimentType
        /**
         * Used in paging together with vector. If specified, returns results before / after the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        lastDate?: string
        /**
         * Used in paging together with lastDate. If specified, sets the sorting order, e.g. desc means descending date order. allowed values: desc, asc
         * @example "desc"
         */
        vector?: AnalyticsFetchThreadsVectorType
        /**
         * Number of items returned in the response
         * @example 10
         */
        limit?: number
        /**
         * Selected sources for threads, seperated by comma
         * @example "Facebook,Instagram"
         */
        selectedSources?: string
        /**
         * Selected keywords for threads, seperated by comma
         * @example "Apple,Microsoft"
         */
        selectedKeywords?: string
        /**
         * Filter by languages, separated by comma
         * @example "english,spanish,french"
         */
        languages?: string
        /**
         * Filter by lost status, allowed values: true, false, both
         * @example "both"
         */
        isLost?: AnalyticsFetchThreadsIsLostType
        /**
         * Filter by mention format, allowed values: keyword, hashtag, both
         * @example "both"
         */
        mentionFormat?: string
        /**
         * Filter by date, returns results after the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        fromDate?: string
        /**
         * Filter by date, returns results before the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchThreadsResponse, any>({
        path: `/v1_private/analytics/fetchThreads`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSV
     * @name AnalyticsFetchThreadsCsvList
     * @summary Download a CSV file
     * @request GET:/v1_private/analytics/fetchThreadsCSV
     */
    analyticsFetchThreadsCsvList: (
      query?: {
        /**
         * Filter by campaign keyword
         * @example "Bellingham"
         */
        keyword?: string
        /**
         * Filter by source, allowed values: twitter, youtube, reddit, news & blogs
         * @example "reddit"
         */
        source?: string
        /**
         * Filter by sentiment, allowed values: POS, NEG, NEU
         * @example "POS"
         */
        sentiment?: FetchThreadsCSVSentimentType
        /**
         * Used in paging together with vector. If specified, returns results before / after the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        lastDate?: string
        /**
         * Used in paging together with lastDate. If specified, sets the sorting order, e.g. desc means descending date order. allowed values: desc, asc
         * @example "desc"
         */
        vector?: FetchThreadsCSVVectorType
        /**
         * Number of items returned in the response
         * @example 10
         */
        limit?: number
        /**
         * Selected sources for threads, seperated by comma
         * @example "Facebook,Instagram"
         */
        selectedSources?: string
        /**
         * Selected keywords for threads, seperated by comma
         * @example "Apple,Microsoft"
         */
        selectedKeywords?: string
        /**
         * Filter by languages, separated by comma
         * @example "english,spanish,french"
         */
        languages?: string
        /**
         * Filter by lost status, allowed values: true, false, both
         * @example "both"
         */
        isLost?: FetchThreadsCSVIsLostType
        /**
         * Filter by mention format, allowed values: keyword, hashtag, both
         * @example "both"
         */
        mentionFormat?: string
        /**
         * Filter by date, returns results after the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        fromDate?: string
        /**
         * Filter by date, returns results before the date
         * @example "2023-01-24T14:49:09.000Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<File, any>({
        path: `/v1_private/analytics/fetchThreadsCSV`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsFetchTopSourcesList
     * @summary Get Top Sources
     * @request GET:/v1_private/analytics/fetchTopSources
     */
    analyticsFetchTopSourcesList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
        /**
         * Filter by Campaign ID
         * @example "63ef6abdb64d85ff5d735782"
         */
        campaign?: string
        /** Filter by Category */
        category?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchTopSourcesResponse, any>({
        path: `/v1_private/analytics/fetchTopSources`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name AnalyticsTopKeywordsList
     * @summary Get Top Keywords
     * @request GET:/v1_private/analytics/topKeywords
     */
    analyticsTopKeywordsList: (
      query?: {
        /**
         * Filter by Date From
         * @example "2023-03-13T20:00:00.000Z"
         */
        fromDate?: string
        /**
         * Filter by Date To
         * @example "2023-03-14T19:59:59.999Z"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalyticsFetchTopKeywordsResponse, any>({
        path: `/v1_private/analytics/topKeywords`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignCategoryList
     * @summary Fetch campaign categories
     * @request GET:/v1_private/campaign/category
     */
    campaignCategoryList: (params: RequestParams = {}) =>
      this.request<CampaignCategory, any>({
        path: `/v1_private/campaign/category`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignFetchDailyPositivityCampaignList
     * @summary Fetch daily positivity campaign data
     * @request GET:/v1_private/campaign/fetchDailyPositivityCampaign
     */
    campaignFetchDailyPositivityCampaignList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<DailyPositivityCampaignResponse, any>({
        path: `/v1_private/campaign/fetchDailyPositivityCampaign`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignFetchTopLocationList
     * @summary Fetch top location data
     * @request GET:/v1_private/campaign/fetchTopLocation
     */
    campaignFetchTopLocationList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CampaignFetchTopLocationData, any>({
        path: `/v1_private/campaign/fetchTopLocation`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignFetchTopSourceList
     * @summary Fetch top source data
     * @request GET:/v1_private/campaign/fetchTopSource
     */
    campaignFetchTopSourceList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CampaignFetchTopSourceData, any>({
        path: `/v1_private/campaign/fetchTopSource`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignList
     * @summary Fetch campaign
     * @request GET:/v1_private/campaign
     */
    campaignList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<FetchCampaignsResponse, any>({
        path: `/v1_private/campaign`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignCreate
     * @summary Create campaign
     * @request POST:/v1_private/campaign
     */
    campaignCreate: (data: CampaignPayload, params: RequestParams = {}) =>
      this.request<CampaignResponse, any>({
        path: `/v1_private/campaign`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignDetail
     * @summary Fetch campaign
     * @request GET:/v1_private/campaign/{id}
     */
    campaignDetail: (id: string, params: RequestParams = {}) =>
      this.request<FetchCampaignByIdResponse, any>({
        path: `/v1_private/campaign/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignUpdate
     * @summary Update campaign
     * @request PUT:/v1_private/campaign/{id}
     */
    campaignUpdate: (
      id: string,
      data: CampaignPayload,
      params: RequestParams = {}
    ) =>
      this.request<UpdateCampaignResponse, any>({
        path: `/v1_private/campaign/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignDelete
     * @summary Delete campaign
     * @request DELETE:/v1_private/campaign/{id}
     */
    campaignDelete: (id: string, params: RequestParams = {}) =>
      this.request<CampaignDelete, any>({
        path: `/v1_private/campaign/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Thread Management
     * @name CampaignMakeThreadIrrelevantCreate
     * @summary Mark threads as irrelevant
     * @request POST:/v1_private/campaign/makeThreadIrrelevant
     */
    campaignMakeThreadIrrelevantCreate: (
      data: {
        /** Array of thread IDs to mark as irrelevant */
        ids: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          success?: boolean
        },
        | {
            /** @example "An array of thread IDs is required in the request body" */
            error?: string
          }
        | {
            /** @example "An error occurred while marking the threads as irrelevant" */
            error?: string
          }
      >({
        path: `/v1_private/campaign/makeThreadIrrelevant`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Thread Management
     * @name CampaignMakeThreadRelevantCreate
     * @summary Mark threads as irrelevant
     * @request POST:/v1_private/campaign/makeThreadRelevant
     */
    campaignMakeThreadRelevantCreate: (
      data: {
        /** Array of thread IDs to mark as irrelevant */
        ids: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          success?: boolean
        },
        | {
            /** @example "An array of thread IDs is required in the request body" */
            error?: string
          }
        | {
            /** @example "An error occurred while marking the threads as irrelevant" */
            error?: string
          }
      >({
        path: `/v1_private/campaign/makeThreadRelevant`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Thread Management
     * @name CampaignRemoveThreadDelete
     * @summary Remove threads
     * @request DELETE:/v1_private/campaign/removeThread
     */
    campaignRemoveThreadDelete: (
      data: {
        /** Array of thread IDs to remove */
        ids: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          success?: boolean
        },
        | {
            /** @example "An array of thread IDs is required in the request body" */
            error?: string
          }
        | {
            /** @example "An error occurred while removing the threads" */
            error?: string
          }
      >({
        path: `/v1_private/campaign/removeThread`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Thread Management
     * @name CampaignReportThreadCreate
     * @summary Report threads
     * @request POST:/v1_private/campaign/reportThread
     */
    campaignReportThreadCreate: (
      data: {
        /** Array of thread IDs to report */
        ids: string[]
        /** Optional message for the report */
        message?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          success?: boolean
        },
        | {
            /** @example "An array of thread IDs is required in the request body" */
            error?: string
          }
        | {
            /** @example "An error occurred while reporting the threads" */
            error?: string
          }
      >({
        path: `/v1_private/campaign/reportThread`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the sentiment value for one or more threads in the enriched thread collection
     *
     * @tags Campaign
     * @name CampaignChangeSentimentCreate
     * @summary Change sentiment for multiple threads
     * @request POST:/v1_private/campaign/change-sentiment
     * @secure
     */
    campaignChangeSentimentCreate: (
      data: {
        /**
         * Array of thread IDs to update
         * @example ["64b4253d12da87805f15112f","64b4253d12da87805f15112e"]
         */
        ids: string[]
        /**
         * New sentiment value
         * @example "POS"
         */
        sentiment: 'POS' | 'NEG' | 'NEU'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          success?: boolean
          /**
           * Number of threads that were modified
           * @example 2
           */
          modified?: number
        },
        | {
            /** @example "An array of thread IDs is required in the request body" */
            error?: string
          }
        | {
            /** @example "An error occurred while changing the sentiment" */
            error?: string
          }
      >({
        path: `/v1_private/campaign/change-sentiment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name CampaignTargetList
     * @summary Fetch campaign target
     * @request GET:/v1_private/campaign/target
     */
    campaignTargetList: (params: RequestParams = {}) =>
      this.request<CampaignTargetResponse, any>({
        path: `/v1_private/campaign/target`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keyword
     * @name KeywordAddRevewIdCreate
     * @summary Add review id for keyword, e.g google place id or booking.com link
     * @request POST:/v1_private/keyword/addRevewId
     */
    keywordAddRevewIdCreate: (
      query: {
        keyword: string
        campaign: string
        placeId?: string
        TripadvisorPlaceId: string
        bookingLink: string
      },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/v1_private/keyword/addRevewId`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keywords
     * @name KeywordFetchDailyTrendsList
     * @summary Get daily trends on keyword
     * @request GET:/v1_private/keyword/fetchDailyTrends
     */
    keywordFetchDailyTrendsList: (
      query?: {
        /** get data from */
        fromDate?: string
        /** get data until */
        toDate?: string
        /**
         * Filter by campaign id
         * @example "63847e707c37f9574546d86f"
         */
        campaign?: string
        /**
         * Filter by campaign keyword
         * @example "ABC"
         */
        keyword?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<KeywordFetchDailyTrends, any>({
        path: `/v1_private/keyword/fetchDailyTrends`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keyword
     * @name KeywordFetchKeywordsWithStatsList
     * @summary Get Keyword with stats
     * @request GET:/v1_private/keyword/fetchKeywordsWithStats
     */
    keywordFetchKeywordsWithStatsList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<KeywordFetchKeywordsWithStats[], any>({
        path: `/v1_private/keyword/fetchKeywordsWithStats`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keyword
     * @name KeywordFetchSentimentAnalysisList
     * @summary Get Sentiment Analysis
     * @request GET:/v1_private/keyword/fetchSentimentAnalysis
     */
    keywordFetchSentimentAnalysisList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
        /**
         * Filter by Date
         * @example "63847e707c37f9574546d86f"
         */
        campaign?: string
        /**
         * Filter by Date
         * @example "Abc Abc"
         */
        keyword?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<KeywordFetchSentimentAnalysis, any>({
        path: `/v1_private/keyword/fetchSentimentAnalysis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keyword
     * @name KeywordFetchSentimentChartList
     * @summary Get Sentiment chart data
     * @request GET:/v1_private/keyword/fetchSentimentChart
     */
    keywordFetchSentimentChartList: (
      query?: {
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        fromDate?: string
        /**
         * Filter by Date
         * @example "2023-03-14"
         */
        toDate?: string
        /**
         * Filter by Date
         * @example "63847e707c37f9574546d86f"
         */
        campaign?: string
        /**
         * Filter by Date
         * @example "Abc Abc"
         */
        keyword?: string
        /**
         * list of campaign ids
         * @example "63847e707c37f9574546d86f,63847e707c37f9574546d86f"
         */
        campaigns?: string
        /**
         * list of sources
         * @example "asd,asd"
         */
        sources?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<KeywordFetchSentimentChart, any>({
        path: `/v1_private/keyword/fetchSentimentChart`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Keywords
     * @name KeywordFetchTopSourcesList
     * @summary Get top sources
     * @request GET:/v1_private/keyword/fetchTopSources
     */
    keywordFetchTopSourcesList: (
      query?: {
        /** get data from */
        fromDate?: string
        /** get data until */
        toDate?: string
        /**
         * Filter by campaign id
         * @example "63847e707c37f9574546d86f"
         */
        campaign?: string
        /**
         * Filter by campaign keyword
         * @example "Abc Abc"
         */
        keyword?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<KeywordFetchTopSources[], any>({
        path: `/v1_private/keyword/fetchTopSources`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TripAdvisor
     * @name TripadvisorSearchTripAdvisorList
     * @summary Search TripAdvisor locations
     * @request GET:/v1_private/tripadvisor/searchTripAdvisor
     */
    tripadvisorSearchTripAdvisorList: (
      query: {
        /** Search query for TripAdvisor locations */
        searchQuery: string
        /** Language for the search results */
        language: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            location_id?: string
            name?: string
            address_obj?: {
              street1?: string
              street2?: string
              city?: string
              country?: string
              postalcode?: string
              address_string?: string
            }
          }[]
        },
        void
      >({
        path: `/v1_private/tripadvisor/searchTripAdvisor`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
}
