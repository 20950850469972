const LATIN_LETTER_REGEX = /^[a-z]+$/i

export const capitalizeWords = (sentence: string): string => {
  return sentence
    .split(' ')
    .map((word: string) => {
      const firstChar = word.charAt(0)

      if (LATIN_LETTER_REGEX.test(firstChar)) {
        return firstChar.toLocaleUpperCase() + word.slice(1)
      }

      return word
    })
    .join(' ')
}
