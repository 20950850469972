import { useQuery } from '@tanstack/react-query'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
  AnalyticsFetchThreadsVectorType,
} from 'src/services/__generated__/api'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { UseGetNeutralLiveNotificationsQueryParams } from './interfaces/useGetNeutralLiveNotificationsQuery.interface'
import { QueryKey } from '../enums/queryKey.enum'

const getNeutralLiveNotifications = async (
  lastDate?: string,
  limit?: number
): Promise<AnalyticsFetchThreadsResponseDataItem[] | null> => {
  if (!lastDate || !limit) {
    return null
  }

  const {
    data: { data },
  } = await AnalyticsApi.v1Private.analyticsFetchThreadsList({
    lastDate,
    limit,
    sentiment: AnalyticsFetchThreadsSentimentType.NEU,
    vector: AnalyticsFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetNeutralLiveNotificationsQuery = (
  params: UseGetNeutralLiveNotificationsQueryParams
) => {
  return useQuery({
    queryFn: () => getNeutralLiveNotifications(params.lastDate, params.limit),
    queryKey: [
      QueryKey.GET_NEUTRAL_LIVE_NOTIFICATIONS,
      ...Object.values(params),
    ],
    refetchInterval: Infinity,
  })
}
