import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import en from 'src/constants/en'
import { AnalyticsApi } from 'src/services/analyticsApi'

export interface changeSentimentResponse {
  modified?: number
  success?: boolean
}

export interface UseChangeSentimentParams {
  onError?: () => void
  onSuccess?: () => void
}

const changeSentiment = async ({
  ids,
  sentiment,
}: {
  ids: string[]
  sentiment: 'POS' | 'NEG' | 'NEU'
}): Promise<changeSentimentResponse | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignChangeSentimentCreate({
    ids,
    sentiment,
  })

  return data
}

export const useChangeSentimentType = ({
  onError,
  onSuccess,
}: UseChangeSentimentParams = {}) => {
  const notifySuccess = () => {
    toast.success(en.CHANGE_SENTIMENT_TYPE_SUCCESS_MESSAGE)
  }

  const notifyError = () => {
    toast.error(en.CHANGE_SENTIMENT_TYPE_ERROR_MESSAGE)
  }

  return useMutation({
    mutationFn: changeSentiment,
    onError: () => {
      if (onError) {
        onError()
        notifyError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
        notifySuccess()
      }
    },
  })
}
